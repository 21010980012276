import * as Sentry from '@sentry/remix';
import type { Primitive } from 'utility-types';

interface AdditionalContext {
  tags?: Record<string, Primitive>;
  extra?: Record<string, Primitive | Primitive[]>;
}

export function captureException(exception: unknown, context?: AdditionalContext) {
  Sentry.captureException(exception, context);
}

export function setUser(user: { id: string } | null) {
  Sentry.setUser(user ? { id: user.id } : null);
}
